import React, { Component } from 'react';
import "../style/profile/profile.css"

class ProfileCard extends Component {
    render() {
        return (
            <div className="profile-card">
                <div className="filter">
                    <h4>YASİN KAMIŞ</h4>
                    <h5>Front - End Developer</h5>
                </div>
            </div>
        )
    }
}

export default ProfileCard
