import React from 'react'
import "../style/component/NotFound/notfound.css"

export default function NotFound() {
    return (
        <div className="notfound">
            <h1>404</h1>
            <h1>Page Not Found</h1>
        </div>
    )
}
